// Import the functions you need from the SDKs you need
import { FirebaseApp, initializeApp } from 'firebase/app'
import { Analytics, getAnalytics } from 'firebase/analytics'
import { Firestore, getFirestore } from 'firebase/firestore'
import { FirebaseStorage, getStorage } from 'firebase/storage'
import { Auth, getAuth } from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyARBYDcQosuWr096kAO0FA0lXedRdwIDYc',
  authDomain: 'discounts-93354.firebaseapp.com',
  projectId: 'discounts-93354',
  storageBucket: 'discounts-93354.appspot.com',
  messagingSenderId: '210178103485',
  appId: '1:210178103485:web:b6228919e423f510e8ffd4',
  measurementId: 'G-RY8BTFDE1W'
}

/*
  This is all late bound so that Gatsby can SSG pages that have firebase dependencies
*/

// Initialize Firebase
let _app: FirebaseApp
const getApp = () => {
  if (!_app) {
    _app = initializeApp(firebaseConfig)
  }
  return _app
}

let _firestore: Firestore
export const firestore = () => {
  if (!_firestore) {
    _firestore = getFirestore(getApp())
  }
  return _firestore
}

let _analytics: Analytics
export const analytics = () => {
  if (!_analytics) {
    _analytics = getAnalytics(getApp())
  }
  return _analytics
}

let _storage: FirebaseStorage
export const storage = () => {
  if (!_storage) {
    _storage = getStorage(getApp())
  }
  return _storage
}

let _auth: Auth
export const auth = () => {
  if (!_auth) {
    _auth = getAuth(getApp())
  }
  return _auth
}