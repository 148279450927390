import { Menu, Transition } from '@headlessui/react'
import { Link } from 'gatsby'
import React from 'react'
import { signout } from '../../services/auth'
import { useAuthStore } from '../../stores/auth-store'
import { classNames } from '../../utility/class-names'
import { CurrencyDollarIcon, ArrowRightIcon } from '@heroicons/react/24/outline'

export default () => {
  const authStore = useAuthStore()

  if (!authStore.isLoaded) {
    return <div className="py-4"></div>
  }

  if (!authStore.user){
    return <div className="py-1 mt-2">
      <Link to='/login' className='login-button mr-4 border-2 border-primary-500 rounded-md'>Sign in</Link>
      <Link to='/memberships/choose' className='hover:underline hidden sm:inline'>Discover your discounts <ArrowRightIcon className='inline w-4 mb-1'/></Link>
    </div>
  }

  const handleSignout = async () => {
    await signout()
  }

  return (
    <div className='flex space-x-4 items-center button button-secondary button-condensed px-2'>
      <Link to='/memberships' className=' font-normal text-md'>
        My Offers
      </Link>
      <Menu as="div" className='border-l-2 border-white/50 pl-2'>
        <Menu.Button className="bg-white rounded-full flex text-sm ring-2 ring-white ring-opacity-20 focus:outline-none focus:ring-opacity-100">
          <span className="sr-only">Open user menu</span>
          <img className="h-8 w-8 rounded-full" referrerPolicy='no-referrer' alt={authStore.user.displayName ?? 'User'} src={authStore.user.photoURL!} />
        </Menu.Button>
        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right z-40 absolute -right-2 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <Menu.Item>
                {({ active }) => (
                  <a
                    onClick={handleSignout}
                    className={classNames(
                      active ? 'bg-gray-100' : '',
                      'block cursor-pointer px-4 py-2 text-sm text-gray-700'
                    )}
                  >
                    Logout
                  </a>
                )}
              </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  )
}