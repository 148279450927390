exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-images-tsx": () => import("./../../../src/pages/admin/images.tsx" /* webpackChunkName: "component---src-pages-admin-images-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-categories-item-tsx": () => import("./../../../src/pages/categories/item.tsx" /* webpackChunkName: "component---src-pages-categories-item-tsx" */),
  "component---src-pages-companies-company-id-tsx": () => import("./../../../src/pages/companies/{Company.id}.tsx" /* webpackChunkName: "component---src-pages-companies-company-id-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-memberships-choose-tsx": () => import("./../../../src/pages/memberships/choose.tsx" /* webpackChunkName: "component---src-pages-memberships-choose-tsx" */),
  "component---src-pages-memberships-index-tsx": () => import("./../../../src/pages/memberships/index.tsx" /* webpackChunkName: "component---src-pages-memberships-index-tsx" */),
  "component---src-pages-offers-offer-id-tsx": () => import("./../../../src/pages/offers/{Offer.id}.tsx" /* webpackChunkName: "component---src-pages-offers-offer-id-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */)
}

