import { getDocs, collection, setDoc, doc, writeBatch } from 'firebase/firestore'
import { Company } from '../../contracts/company'
import { firestore } from '../../infrastructure/firebase'

export const listCompanies = async (): Promise<Company[]> => {
  // const q = query(collection(firestore, 'companies'), where('isActive', '==', true));
  // const snapshot = await getDocs(q)
  const snapshot = await getDocs(collection(firestore(), 'companies'))
  const companies = snapshot.docs
    .map(doc => doc.data() as Company)
    .sort((a, b) => a.name.localeCompare(b.name))
  return companies
}

export const save = async (company: Company): Promise<void> => {
  const { id } = company
  await setDoc(doc(firestore(), `companies`, id), company)
}

export const batchSet = async (companiesToSave: Company[], companiesToDelete: Company[]): Promise<void> => {
  while (companiesToSave.length > 0) {
    const items = companiesToSave.splice(0, 500)
    const batch = writeBatch(firestore())
    items.forEach(company => {
      const ref = doc(firestore(), 'companies', company.id)
      batch.set(ref, company)
    })
    await batch.commit()
  }

  while (companiesToDelete.length > 0) {
    const items = companiesToDelete.splice(0, 500)
    const batch = writeBatch(firestore())
    items.forEach(company => {
      const ref = doc(firestore(), 'companies', company.id)
      batch.delete(ref)
    })
    await batch.commit()
  }
}