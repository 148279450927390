module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-layout@3.20.0_gatsby@4.20.0/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/discounts/discounts/apps/app/src/layouts/index.tsx"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@4.20.0_gatsby@4.20.0_graphql@15.8.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/logos/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c4036d86765bbf6f462b24f36229dd1c"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-google-gtag@4.21.0_gatsby@4.20.0_react-dom@18.2.0_react@18.2.0/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RY8BTFDE1W"],"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@4.20.0_@types+node@17.0.45_babel-eslint@10.1.0_react-dom@18.2.0_react@18.2.0_typescript@4.7.4/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
