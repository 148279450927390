import { getDocs, collection, setDoc, doc, writeBatch } from 'firebase/firestore'
import { OfferRow } from '../../contracts/offer-row'
import { firestore } from '../../infrastructure/firebase'

export const listOffers = async (): Promise<OfferRow[]> => {
  const snapshot = await getDocs(collection(firestore(), 'offers'))
  const offers = snapshot.docs
    .map(doc => doc.data() as OfferRow)
    .sort((a, b) => a.title.localeCompare(b.title))
  return offers
}

export const save = async (offer: OfferRow): Promise<void> => {
  const { id } = offer
  await setDoc(doc(firestore(), `offers`, id), offer)
}

export const batchSet = async (offersToSave: OfferRow[], offersToDelete: OfferRow[]): Promise<void> => {
  while(offersToSave.length > 0) {
    const items = offersToSave.splice(0, 500)
    const batch = writeBatch(firestore())
    items.forEach(offer => {
      const ref = doc(firestore(), 'offers', offer.id)
      batch.set(ref, offer)
    })
    await batch.commit()
  }
  
  while(offersToDelete.length > 0) {
    const items = offersToDelete.splice(0, 500)
    const batch = writeBatch(firestore())
    items.forEach(offer => {
      const ref = doc(firestore(), 'offers', offer.id)
      batch.delete(ref)
    })
    await batch.commit()
  }
}