import { Company } from '../contracts/company'
import * as api from './api/company'

export const listCompanies = async () => {
  return api.listCompanies()
}

export const saveCompany = async (company: Company) => {
  return api.save(company)
}

export const saveCompanies = async (oldState: Company[], newState: Company[]) => {
  // Ensure no 'undefined' fields
  newState.forEach(company => {
    company.description = company.description ?? ''
    company.memberLink = company.description ?? ''
  })
  const companiesToSaveIds = newState.map(c => c.id)
  const companiesToDelete = oldState!.filter(c => !companiesToSaveIds.includes(c.id))
  await api.batchSet(newState, companiesToDelete)
}