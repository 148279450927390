import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { categories } from '../../contracts/categories'
import { classNames } from '../../utility/class-names'

export default ({ path }: { path: string }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded)
  }
  
  return (
    <div className='relative pt-3'>
      <button
        onClick={() => toggleIsExpanded()}
        aria-label='toggle-navigation'
        className='lg:hidden relative flex items-center space-x-2'>
        <span>Categories</span>
        {isExpanded
          ? <ChevronDownIcon className='w-5 h-5' />
          : <ChevronRightIcon className='w-5 h-5' />
        }
      </button>
      <nav className={classNames(
        'py-2 lg:py-0 overflow-hidden grid lg:grid-cols-4 grid-cols-2 space-x-0 space-y-1 transition-all duration-300',
        isExpanded ? 'max-h-screen' : 'max-h-0 lg:max-h-screen'
      )}
      >
        {categories.map(({ name: category }) => {
          const targetPath = `/categories/${category.toLowerCase().replace(/ /g, '-')}`
          const isCurrentPage = path === targetPath
          return <Link
            key={category}
            to={targetPath}
            onClick={() => setIsExpanded(false)}
            className={classNames(
              'text-sm font-medium rounded-md bg-white px-3 py-2 hover:text-white hover:bg-opacity-10',
              isCurrentPage ? 'text-white bg-opacity-10' : 'text-gray-200 bg-opacity-0'
            )}
            aria-current={category ? 'page' : undefined}
          >
            {category.replace(/-/g, ' ')}
          </Link>
        })}
      </nav>
    </div>
  )
}