import { ChevronRightIcon, MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'gatsby'
import React, { ChangeEvent, KeyboardEventHandler, useEffect, useRef, useState } from 'react'
import { Company } from '../../contracts/company'
import { listCompanies } from '../../services/company'
import { useCompanyStore } from '../../stores/company-store'
import { classNames } from '../../utility/class-names'
import Loading from '../loading/loading'
import useDebounce from '../use-debounce/use-debounce'

const SearchResult = ({ company, linkClicked }: { company: Company, linkClicked: () => void }) => {
  return (
    <Link to={`/companies/${company.id}`} onClick={() => linkClicked()} className='block'>
      <div className='pl-6 relative w-full'>
        <ChevronRightIcon className='w-5 h-5 absolute left-0 top-1' />
        <div className='text-xl'>{company.name}</div>
        <div className='text-md text-gray-300'>{company.description}</div>
      </div>
    </Link>
  )
}

export default ({ resultsAbsolute }: { resultsAbsolute: boolean }) => {
  const [searchInput, setSearchInput] = useState<string>('')
  const { companies, setCompanies } = useCompanyStore()
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(false)
  const [searchResults, setSearchResults] = useState<Company[]>()

  useDebounce(async () => {
    let companiesToFilter: Company[]
    
    if (searchInput.trim() === '') {
      setSearchResults(undefined)
      return
    }

    if (companies === undefined && isLoadingCompanies === false) {
      setIsLoadingCompanies(true)
      companiesToFilter = await listCompanies()
      setCompanies(companiesToFilter)
      setIsLoadingCompanies(false)
    } else if (companies === undefined) {
      // Companies are loading, when resumed will filter to latest search input
      return
    } else {
      companiesToFilter = companies
    }

    const matchingCompanies = companiesToFilter
      .filter(company => company.name.toLowerCase().includes(searchInput.toLowerCase()))
      .slice(0, 20)

    setSearchResults(matchingCompanies)
  }, [searchInput], 800)

  useEffect(() => {
    clearSearch()
  }, [])
  
  const handleSearchChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value)
  }

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Escape') {
      clearSearch()
    }
  }

  const inputRef = useRef(null)
  const handleClearResults = () => {
    clearSearch()
  }

  const clearSearch = () => {
    (inputRef.current as any).value = ''
    setSearchResults(undefined)
    setSearchInput('')
  }

  return (
    <div className='search space-y-4 relative'>
      <div className='search__input flex items-center'>
        <input
          type='text'
          placeholder='Search Deals'
          onKeyDown={handleKeyDown}
          ref={inputRef}
          className='bg-primary-800 border-0 rounded-full placeholder-gray-400 text-sm transition-all w-full py-3 px-6'
          onChange={handleSearchChanged}
        ></input>
        <MagnifyingGlassIcon className='w-5 h-5 block -ml-10 text-gray-200' />
      </div>
      {(isLoadingCompanies || searchResults) && 
        <div className={classNames(
          'search__results max-h-[600px] overflow-y-auto bg-primary-600 p-4',
          resultsAbsolute ? 'absolute z-10 shadow-xl w-full' : 'relative'
        )}>
          <button
            className='absolute right-2 top-2'
            onClick={handleClearResults}
          >
            <XMarkIcon className='w-6 h-6' />
          </button>

          {isLoadingCompanies &&
            <div className='flex justify-center'>
              <Loading label='Searching...' />
            </div>
          }

          {searchResults &&
            <div className='space-y-6'>
              {searchResults.length === 0 && <span>0 results found</span>}
              {searchResults.map(company => <SearchResult key={company.id} company={company} linkClicked={() => clearSearch()} />)}
            </div>
          }
        </div>
      }
    </div>

  )
}