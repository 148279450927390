import create from 'zustand'
import { Membership } from '../contracts/membership'
import { Offer } from '../contracts/offer'

export interface MembershipStore {
  membership: Membership | undefined
  membershipOffers: Offer[] | undefined
  setMembership: (membership: Membership | undefined) => void
  setMembershipOffers: (membershipOffers: Offer[] | undefined) => void
}

export const useMembershipStore = create<MembershipStore>(set => ({
  membership: undefined,
  membershipOffers: undefined,
  setMembership: (membership: Membership | undefined) => set(() => ({ membership })),
  setMembershipOffers: (membershipOffers: Offer[] | undefined) => set(() => ({ membershipOffers }))
}))