import { AuthProvider, FacebookAuthProvider, GoogleAuthProvider, getAuth, isSignInWithEmailLink, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup, signOut } from 'firebase/auth'
import { auth } from '../infrastructure/firebase'
import { AuthStore } from '../stores/auth-store'

export const startSignInWithEmailLink = async (email: string) => {
  await sendSignInLinkToEmail(auth(), email, { url: 'https://discounts-93354.web.app/' })
  // Save the email locally so you don't need to ask the user for it again
  // if they open the link on the same device
  window.localStorage.setItem('emailForSignIn', email)
}

export const confirmSignInWithEmailLink = async () => {
  if (isSignInWithEmailLink(auth(), window.location.href)) {
    // Additional state parameters can also be passed via URL.
    // This can be used to continue the user's intended action before triggering
    // the sign-in operation.
    // Get the email if available. This should be available if the user completes
    // the flow on the same device where they started it.
    let email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again. For example:
      email = window.prompt('Please provide your email for confirmation')
    }
    // The client SDK will parse the code from the link for you.
    signInWithEmailLink(auth(), email!, window.location.href)
      .then((result) => {
        // Clear email from storage.
        window.localStorage.removeItem('emailForSignIn')
        console.log('Signed in via email link', result)
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        console.error('Failed to sign in', error)
      })
  }
}

export const signInWithGoogle = async (authStore: AuthStore) => {
  const provider = new GoogleAuthProvider()
  const result = await signinWithProvider(provider)
  const credential = GoogleAuthProvider.credentialFromResult(result)
  const token = credential!.accessToken
  const user = result.user

  authStore.setUser(user)
}

export const signInWithFacebook = async (authStore: AuthStore) => {
  const provider = new FacebookAuthProvider()
  const result = await signinWithProvider(provider)
  const credential = FacebookAuthProvider.credentialFromResult(result)
  const token = credential!.accessToken
  const user = result.user

  authStore.setUser(user)
}

const signinWithProvider = async (provider: AuthProvider) => {
  return signInWithPopup(auth(), provider)

    // .then((result) => {
    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   const credential = GoogleAuthProvider.credentialFromResult(result)
    //   const token = credential!.accessToken
    //   // The signed-in user info.
    //   const user = result.user
    //   console.log('Signed in as user', user)
    // }).catch((error) => {
    //   // Handle Errors here.
    //   const errorCode = error.code
    //   const errorMessage = error.message
    //   // The email of the user's account used.
    //   const email = error.customData.email
    //   // The AuthCredential type that was used.
    //   const credential = GoogleAuthProvider.credentialFromError(error)
    //   // ...
    // })
}

export const signout = async () =>
  signOut(getAuth())
