import { doc, getDoc, setDoc } from 'firebase/firestore'
import { Membership } from '../contracts/membership'
import { firestore } from '../infrastructure/firebase'

export const saveMembership = async (userId: string, companyIds: string[]) => {
  await setDoc(doc(firestore(), `memberships`, userId), {
    companyIds
  })
}

export const getMembership = async (userId: string): Promise<Membership | undefined> => {
  const snapshot = await getDoc(doc(firestore(), `memberships/${userId}`))
  return snapshot.data() as Membership | undefined
}