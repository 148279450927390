import { Popover } from '@headlessui/react'
import { Link } from 'gatsby'
import * as React from 'react'
import Navigation from '../navigation/navigation'
import Search from '../search/search'
import AuthHeader from './auth-header'

const Header = () => {
  return (
    <div className="pt-5 flex items-center">
      {/* Logo */}
      <div className="left-0 flex-1">
        <Link to="/" className="flex items-center space-x-3 font-semibold">
          <img
            className="h-11 w-auto"
            src="/logos/logo.svg"
            alt="Disco Deals"
          />
          <span className="text-3xl md:text-4xl font-title tracking-wide">Disco Deals</span>
        </Link>
      </div>

      <div className="items-center space-x-4 flex">
        <div className='w-[500px] hidden lg:block'>
          <Search resultsAbsolute={true} />
        </div>
        <AuthHeader />
      </div>
    </div>
  )
}

export default function DefaultLayout({ children, path }: any) {
  return (
    <>
      <div className="min-h-full">
        <Popover as="header" className="bg-gradient-to-l from-primary-700 to-primary-600 text-white">
          <div className="mx-auto px-4 sm:px-6 max-w-7xl lg:px-8 space-y-4">
            <Header />
            <div className='lg:hidden'>
              <Search resultsAbsolute={false} />
            </div>
            <div className="border-t border-white border-opacity-20 lg:py-5">
              <Navigation path={path} />
            </div>
          </div>
        </Popover>
        <div className="pb-8">
          {children}
        </div>
        <footer>
          <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl ">
            <div className="border-t border-gray-200 dark:border-gray-500 py-8 text-sm text-gray-500 text-center sm:text-left flex justify-center gap-2">
              <span className="block sm:inline">&copy; {new Date().getFullYear()} Disco Deals.</span>
              <span className="block sm:inline">All rights reserved.</span>
            </div>
          </div>
        </footer>
      </div>
    </>
  )
}
