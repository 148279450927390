import { Company } from '../contracts/company'
import { Offer } from '../contracts/offer'
import { OfferRow } from '../contracts/offer-row'
import * as api from './api/offer'
import { listCompanies } from './company'

export const listOffers = async () => {
  return api.listOffers()
}

export const saveOffer = async (offer: OfferRow) => {
  return api.save(offer)
}

export const saveOffers = async (oldState: OfferRow[], newState: OfferRow[]) => {
  // Ensure no undefined fields
  newState.forEach(offer => {
    const keys = Object.keys(offer) as any[] as (keyof OfferRow)[]
    keys.forEach(key => {
      if (offer[key] === undefined) {
        delete offer[key]
      }
    })
  })
  const offersToSaveIds = newState.map(c => c.id)
  const offersToDelete = oldState!.filter(c => !offersToSaveIds.includes(c.id))
  await api.batchSet(newState, offersToDelete)
}

export const listOffersForMembership = async (companyIds: string[]): Promise<Offer[]> => {
  const [allOffers, allCompanies] = await Promise.all([listOffers(), listCompanies()])

  const companies = allCompanies.filter(company => companyIds.includes(company.id))

  const availableOffers: Offer[] = []

  companies.forEach(company => {
    const offersForCompany = allOffers
      .filter(offer => offer.availableThrough === company.id)
      .map(offer => ({...offer, availableThrough: company, offeredBy: allCompanies.find(offeredBy => offeredBy.id === offer.offeredBy) })) as any as Offer[]

    availableOffers.push(...offersForCompany)
  })

  return availableOffers.sort((a, b) => a.offeredBy.name.localeCompare(b.offeredBy.name))
}