import create from 'zustand'
import { Company } from '../contracts/company'

export interface CompanyStore {
  companies: Company[] | undefined
  setCompanies: (companies: Company[] | undefined) => void
}

export const useCompanyStore = create<CompanyStore>(set => ({
  companies: undefined,
  setCompanies: (companies: Company[] | undefined) => set(() => ({ companies })),
}))