import { onAuthStateChanged, User } from 'firebase/auth'
import React, { useEffect } from 'react'
import DefaultLayout from '../components/default-layout/default-layout'
import { auth } from '../infrastructure/firebase'
import { getMembership } from '../services/memberships'
import { listOffersForMembership } from '../services/offer'
import { useAuthStore } from '../stores/auth-store'
import { useMembershipStore } from '../stores/membership-store'

export default ({ children, pageContext, location, ...rest }: any) => {
  const setUser = useAuthStore(state => state.setUser)
  const setMembership = useMembershipStore(state => state.setMembership)
  const setMembershipOffers = useMembershipStore(state => state.setMembershipOffers)
  
  useEffect(() => {
    onAuthStateChanged(auth(), user => {
      setUser(user ?? undefined)
      if (user) {
        loadMembershipOffers(user)
      }
    })
  }, [])

  const loadMembershipOffers = async (user: User) => {
    setMembership(undefined)
    setMembershipOffers(undefined)
    
    const membership = await getMembership(user.uid)
    setMembership(membership)

    if (membership) {
      const offers = await listOffersForMembership(membership.companyIds)
      setMembershipOffers(offers)
    }
  }

  const path = location.pathname
  if (path === '/login') {
    return children
  } else {
    return (
      <DefaultLayout path={location.pathname}>
        {children}
      </DefaultLayout>
    )
  }
}