import { User } from 'firebase/auth'
import create from 'zustand'

export interface AuthStore {
  user: User | undefined
  isLoaded: boolean
  setUser: (user: User | undefined) => void
}

export const useAuthStore = create<AuthStore>(set => ({
  user: undefined,
  isLoaded: false,
  setUser: (user: User | undefined) => set(() => ({ user, isLoaded: true })),
}))